import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
// import  * as moment  from "moment-timezone";

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public EST_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
  public USERTOKEN: any = '';
  public selectedRestaurantId = null;
  public selectedRestaurantUid = null;
  public loggedInUserDetails = null;
  public loggedInUserRole = null;
  // public WEB_SITE_URL = environment.WEBSITE_URL;
  public FIREBASE_TOKEN = '';

  public PER_PAGE_DATA = 10;

  public STATE_LIST: any = [];

  constructor(
    private router: Router,
  ) {
    this.loggedInUserRole = this.getLoggedInUserRole();
  }

  /**
  * Marks all controls in a form group as touched
  * @param formGroup - The form group to touch
  */
  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  // /**
  //  * Returns String containing list of error messages to be displayed in a single string(toast)
  //  * @param errors Errors Object Received from API Response
  //  */
  // getErrorMessageList(errors) {
  //   const errList = [];
  //   errors.forEach(error => {
  //     errList.push(error['message']);
  //   });

  //   return errList;
  // }

  /**
   * Set Selected restaurant
   */

  setRestaurant(restaurantId) {
    this.selectedRestaurantId = restaurantId;
    localStorage.setItem('selectedRestaurant', this.selectedRestaurantId);
  }

  setRestaurantName(restaurantName) {
    localStorage.setItem('selectedRestaurantName', restaurantName);
  }

  setRestaurantUid(storeUid) {
    this.selectedRestaurantUid = storeUid;
    localStorage.setItem('selectedRestaurantUid', this.selectedRestaurantUid);
  }

  getLocalRestaurant() {
    return localStorage.getItem('selectedRestaurant');
  }

  getLocalRestaurantName() {
    return localStorage.getItem('selectedRestaurantName');
  }

  getLocalRestaurantUid() {
    return localStorage.getItem('selectedRestaurantUid');
  }

  getLoggedInUserDetails() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.loggedInUserDetails = loggedInUserDetails;
    return loggedInUserDetails;
  }

  getLoggedInUserRole() {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    let role = null;
    if (loggedInUserDetails) {
      role = loggedInUserDetails.roles[0];
    }
    return role;
  }

  clearLocalStorage() {
    localStorage.clear();
    localStorage.removeItem('selectedRestaurant');
    localStorage.removeItem('selectedRestaurantUid');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('loggedInUserDetails');
    localStorage.removeItem('fbToken');
  }

  formatDate(date) {
    if (date) {
      if (moment(date).isValid) {
        return moment(date).toDate();
      } else {
        return date;
      }
    } else {
      return moment().toDate();
    }
  }

  setFirebaseToken() {
    this.FIREBASE_TOKEN = this.getFireBaseToken();
  }

  getFireBaseToken() {
    return localStorage.getItem('fbToken');
  }

  setStates(state) {
    this.STATE_LIST = state;
  }

  getStates() {
    return this.STATE_LIST;
  }

  getCuisines() {
    const list = ['Breakfast and brunch', 'Indian', 'Fast food', 'Pizza', 'Chinese', 'Bakery', 'Healthy', 'Dessert',
      'Caribbean', 'Sushi', 'Bubble tea', 'American', 'Korean', 'Italian', 'Halal', 'Asian', 'Mexican',
      'Vegetarian', 'Vegan', 'Japanese', 'Thali', 'Vietnamese', 'Pakistani', 'Middle eastern', 'Jamaican',
      'Pub', 'Portuguese', 'Juice and smoothies', 'Cafe', 'Deli', 'Gluten free', 'French', 'West Indian',
      'Cantonese', 'Canadian', 'German'];
    return list;
  }

  getfulfillmentTypes() {
    return ['Take Away', 'Delivery', 'Table Reservation'];
  }

  checkRouteForStoreDropdown(url) {
    let flag = false;
    const urlArray = [
      '/pages/home',
      'pages/restaurant/list', '/pages/restaurant/add', '/pages/restaurant/edit/', '/pages/restaurant/details/',
      '/pages/order/list', '/pages/order/add', '/pages/order/edit/', '/pages/order/details/',
      '/pages/customers/list', '/pages/customers/details/',
      '/pages/managers/list', '/pages/managers/add', '/pages/managers/edit/', '/pages/managers/details/',
      '/pages/reservation/list',
    ];
    urlArray.forEach((list: any) => {
      if (url.includes(list)) flag = true;
    });
    return flag;
  }

  // -------------------------------- Letters only method --------------------------------
  latterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  // -------------------------------- Number only method --------------------------------
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // -------------------------------- Decimal method --------------------------------
  decimalOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 46) return true;
    else if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    else return true;
  }

  public convertToSlug(text: string): string {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');
  }

  public getErrorMessageList(error: any) {
    if (error?.status == 401) {
      this.clearLocalStorage();
      this.router.navigate(['auth', 'signin'], { queryParams: { redirectTo: window.location.pathname } });
    }
    else if (error?.error?.error?.details) return error?.error?.error?.details[0]?.message;
    else if (!error?.error?.error?.details) return error?.error?.message;
  }

  public convertTimestampToDate(timestamp) {
    return moment(timestamp).format('MM-DD-YYYY hh:mm A');
    // return momentTz(timestamp).tz(timezone).format('MM-DD-YYYY hh:mm A');
  }
}
