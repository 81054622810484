import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalProviderService {
  isLoggedIn = false;
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Performs HTTP POST Request on given API End Point with given POST data
   * @param apiEndPoint API End Point to perform POST request on
   * @param postObj POST Data to be sent in request
   */
  httpPost(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.post(apiEndPoint, postObj)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res);
          } else {
            reject(res);
          }
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP Put Request on given API End Point with given Put data
   * @param apiEndPoint API End Point to perform Put request on
   * @param postObj POST Data to be sent in request
   */
  httpPut(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.put(apiEndPoint, postObj)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res);
          } else {
            reject(res);
          }
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP GET Request on given api endpoint
   * @param apiEndPoint API End-point to perform GET request on
   */
  httpGet(apiEndPoint: string) {
    return new Promise((resolve, reject) => {
      this.http.get(apiEndPoint)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res);
          } else {
            reject(res);
          }
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP POST Request on given API End Point with given POST data
   * @param apiEndPoint API End Point to perform POST request on
   */
  public httpDelete(apiEndPoint: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(apiEndPoint)
        .subscribe((res: any) => {
          if (res.data != null) {
            resolve(res);
          } else {
            reject(res);
          }
        }, err => {
          reject(err);
        });
    });
  }
}
